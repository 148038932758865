.wrapper .red-star {
    color: red;
}

/* ul.nav li:hover {
    text-decoration: underline;
    cursor: pointer;
}

ul.nav li.active {
    text-decoration: underline;
} */

/* Tab Content Styles */
/* .TabContent { */
    /* text-align: center; */
/* } */

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
}

.upload-btn-wrapper .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.navbar-collapse .nav-item:hover {
    background-color: rgba(1, 1, 1, 0.05);;
}

/* NEW CSS (Sandra) */

*, .wrapper *{
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

.wrapper {
  color: var(--castIron);
}

.wrapper p, .wrapper a {font-size: 16px;}

.wrapper a {
    color: var(--shaleBlue06);
    font-weight: 600;
    text-decoration: none;
}

.wrapper li, .wrapper p, .wrapper div {
    line-height: 2;
    font-size: 16px;
}

.wrapper ul, .wrapper ol {
    padding-left: 14px;
}

.wrapper a:hover {
    color: var(--shaleBlue);
    text-decoration: underline;
}

.wrapper h4 {
    font-size: 24px;
    font-weight: 600;
}

.wrapper h3, .wrapper.job-details .small-title{font-size: 32px;}

.wrapper.services h3{margin: 16px 0;}

.wrapper.services h3:first-of-type{margin-top: 0;}


.wrapper h2{
    font-size: 40px;
    padding: 24px 0;
    font-weight: 600;
}

.wrapper h1{
    font-size: 48px;
    font-weight: 700;
}

.wrapper .btn.btn-primary{
    background-color: var(--shaleBlue);
    border: none;
    box-shadow: 1px 1px 4px var(--shaleBlue05);
    color: var(--whisperGray);
    font-size: 20px;
    margin-bottom: 24px;
    width: auto;
    font-weight: 400;
}

.wrapper .btn.btn-primary:hover, .wrapper .btn.btn-smaller:hover{
    background-color: var(--shaleBlue02);
    color: var(--castIron);
    text-decoration: none;
}

.wrapper .btn.btn-smaller{
    background-color: var(--shaleBlue);
    box-shadow: 1px 1px 4px var(--graphite);
    color: var(--whisperGray);
    margin-bottom: 8px!important;
    border: none;
    font-weight: 400;
}

.main{
    min-height:76vh;
}

.pill-span{
    display: inline-block;
    margin-left: 24px;
    padding: 4px 16px;
    font-size: 12px;
    font-style: italic;
    background-color: var(--shaleBlue03);
    border-radius: 24px;
}

@media only screen and (min-width: 768px) {
    .wrapper h2{
        padding: 32px 0;
    }

    .wrapper .main h1{
        margin-bottom: 32px;
    }
    
    .wrapper .btn.btn-primary{
        margin-bottom: 32px;
    }
    
}

@media only screen and (min-width: 1000px) {
    .wrapper h2{
        padding: 48px 0;
    }
    
    .wrapper .main h1{
        margin-bottom: 48px;
    }

    .wrapper .btn.btn-primary{
        margin-bottom: 48px;
    }
    
}

@media only screen and (min-width: 1200px) {
    .wrapper p, .wrapper a, .wrapper div {font-size: 20px;}

    .wrapper li {
    line-height: 2;
    font-size: 20px;
    }

    .wrapper h4 {font-size: 32px;}

    .wrapper h3, .wrapper.job-details .small-title{font-size: 40px;}

    .wrapper.services h3{margin: 24px 0;}

    .wrapper h2{font-size: 48px;}

    .wrapper h1{font-size: 56px;}
}

/* NAV BAR */

.navbar.dukeBlue{
    background-color: var(--dukeBlue);
    padding: 8px 24px;
    color: var(--whisperGray);
    position: fixed;
    z-index: 10;
    width: 100%;
}

#root:has(#flash-message) .navbar.dukeBlue{
    top:70px;
}

.navbar .collapse.navbar-collapse > ul{
    margin-top: 7px;
}

.navbar-brand .iconLogo{
    height: 24px;
}

.navbar.dukeBlue .navbar-toggler svg path{
    font-size: 16px;
    color: var(--whisperGray);
}



.navbar.dukeBlue .navbar-nav .nav-link.active, .navbar.dukeBlue .navbar-nav .show>.nav-link {
    color: var(--whisperGray);
    margin-left: -8px;
    padding-left: 8px;
}

.navbar.dukeBlue .navbar-nav .nav-link.active:hover, .navbar.dukeBlue .navbar-nav .show>.nav-link:hover {
    background-color: var(--shaleBlue02);
    color: var(--castIron);
    font-weight: 600;
}

.navbar.dukeBlue .collapse.navbar-collapse.show{
    padding: 8px 0;
    margin-top: 8px;
    border-top: solid 1px var(--whisperGray);
}

.navbar.dukeBlue .btn.btn-outline-success{
    background-color: var(--whisperGray);
    color: var(--shaleBlue);
    border: none;
    font-weight: 600;
    width: 100%;
    display: inline-block;
}

.navbar.dukeBlue .btn.btn-outline-success:hover{
    background-color: var(--shaleBlue02);
    color: var(--castIron);
}

.navbar.dukeBlue>div{
    width: 100%;
}

.navbar.dukeBlue>div{
    /* display: flex;
    justify-content: space-between; */
    margin:auto;
}

.navbar.dukeBlue .logoToggle{
    display: flex;
    justify-content: space-between;
    margin:auto;
}

.navbar .logoToggle {
    margin-top: 0 !important;
}

.collapse.navbar-collapse.show span{
    display:none;
}

@media only screen and (min-width: 576px) {
    .navbar.dukeBlue>div{
        max-width: 560px;
    }
  }

@media only screen and (min-width: 768px) {
    .navbar.dukeBlue>div{
        max-width: 720px;
    }
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse .float-right{
    display: none;}

    .navbar.dukeBlue ul{
        margin-left: 24px;
    }
    
    .navbar.dukeBlue li{
        margin-right: 24px;
    }
    .navbar.dukeBlue>div{
        display: flex;
        justify-content: space-between;
    }

    .navbar.dukeBlue .btn.btn-outline-success{
        width: auto;
    }
    .navbar.dukeBlue .navbar-nav .nav-link.active:hover, .navbar.dukeBlue .navbar-nav .show>.nav-link:hover {
        color: var(--shaleBlue02);
        border-bottom: 1px solid var(--shaleBlue02);
        border-top: 1px solid var(--shaleBlue02);
        font-weight: 600;
        background-color: transparent;
        padding: 6px 8px;
    }
}

@media only screen and (min-width: 1000px) {
    .navbar.dukeBlue>div{
        max-width: 1060px;
    }
}


/* HOME PAGE */

/* temporary until i can figure out how to remove extra container div */
.container {
    display: none;
}

.container.flash{
    display: block;
    position: fixed;
    z-index: 11;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

}
.container.flash.hide-flash{
    display: none;
}

.container.flash span{
    margin: auto;
}

.container.wrapper {
    display:block;
}

/* regular code */

.container.wrapper {
    padding: 0;
}

.home-header {
	background-image: var(--gradient01);
	position: relative;
}

.home-header .image-overlay {
	background-image: url('../assets/illustrations/colormash01.png');
	background-size: cover;
	mix-blend-mode: multiply;
    background-position: center;
    background-size: cover;
	height: 350px;
}

.home-header .header-wrapper {
	position: absolute;
	top: 0;
	height: 350px;
	display: grid;
	align-content: center;
	width: 100%;
	padding: 80px 24px 48px 24px;
    color: var(--whisperGray);
    text-align: center;
}

.home-header .header-wrapper h1 {
	color: var(--whisperGray);
}

.wrapper .main{
    padding: 24px 24px 48px 24px;
}

.submit-job {
    position: -webkit-fixed;
    position: fixed;
    bottom: 16px;
    right: 24px;
    z-index: 5;
}

.consultation {
    padding: 20px;
    margin: 10px;
    border-bottom: rgba(0, 0, 0, 0.1) solid;
}

.main p, .main li, .main>div>ul>li:last-of-type {
    padding: 0 0 16px 0;
}

#announcement {
    position: absolute;
    top: 60px;
    z-index: 8;
    width: 100%;
}

#announcement span {
    margin: auto;
}

#announcement .btn-close {
    position: absolute;
    right: 8px;
}

.main .illustrations {
    display: block;
    margin: auto;
    margin-bottom:24px;
    width: 100%;
    max-width: 350px;

}

@media only screen and (min-width: 576px) {
    .wrapper.container, .container-sm {
        max-width: 100%;
    }

    .wrapper .main{
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
    }

    .submit-job {
        position: -webkit-sticky;
        position: sticky;
    }

    .consultation {
        padding: 0;
        margin: 0;
        background-color: transparent;
    }

    .wrapper .panel .btn.btn-primary {
        width: 260px;
        position: block;
    }

    .panel {
        margin-bottom: 32px;
    }

    .home-header .header-wrapper {
        padding: 80px 40px 48px 40px;
    }
  }

@media only screen and (min-width: 768px) {
    .wrapper.container, .wrapper.container-md, .wrapper.container-sm {
        max-width: 100%;
    }
    .wrapper .main{
        max-width: 800px;
        padding: 32px 24px 56px 24px;
    }

    .home-header .header-wrapper {
        padding: 80px 56px 48px 56px;
    }
    
    .main .illustrations {
        margin-bottom:32px;
    }
}

@media only screen and (min-width: 1000px) {
    .wrapper .main{
        max-width: 1100px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: start;
        justify-items: start;
        padding: 48px 24px 64px 24px;
        column-gap: 32px;
    }
    
    .submit-job {
        position: -webkit-sticky;
        position: sticky;
        top: 112px;
    }

    .panel {
        position: -webkit-sticky;
        position: sticky;
        top: 112px;
        padding: 30px;
        border-right: rgba(0, 0, 0, 0.1) solid;
    }

    .consultation {
        border-bottom: none;
    }

    .main .illustrations {
        margin-bottom:48px;
    
    }
}

@media only screen and (min-width: 1200px) {
    .wrapper .main{
        column-gap: 48px;
    }

    .panel {
        padding: 10px;
    }
}

/* Services Page */

.wrapper.services .main, .wrapper.jobs .main{
    padding-top: 80px;
}

.main h1{
    margin-bottom: 24px;
}

.wrapper .accordion-button:not(.collapsed) {
    color: var(--shaleBlue02);
    background-color: var(--shaleBlue03);
    /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
}

.wrapper .accordion-button:not(.collapsed) strong{
    color: var(--shaleBlue);
}

.material-pills p {
    display: inline-block;
    border: 1px solid var(--shaleBlue01);
    color: var(--shaleBlue01);
    border-radius: 8px;
    padding: 4px 8px;
    margin: 0 12px 12px 0;
}

.material-pills h5 {
    margin-bottom: 16px;
}

@media only screen and (min-width: 768px) {
    .wrapper.services .main, .wrapper.jobs .main{
        padding-top: 88px;
    }
}

@media only screen and (min-width: 1000px) {
    .wrapper.services .main, .wrapper.jobs .main{
        padding-top: 104px;
    }

    .main h1{
        grid-column: 2/3;
    }

    .wrapper.services .submit-job{
        grid-row: 1/2;
    }

    .wrapper.services .main>div{
        grid-column: 2/3;
    }
    
}

/*jobs page */

.wrapper table .btn.btn-smaller{
    margin-bottom: 3px!important;
    font-size: 16px;
}

.wrapper.jobs td{
    vertical-align: middle;
}

.wrapper.jobs nav ul{
    padding: 0;
}

.wrapper table{
    margin-bottom: 24px;
}

.wrapper .page-link{
    color: var(--shaleBlue06);
    font-weight: 600;
}

.wrapper .pagination .page-link:hover{
    background-color: var(--shaleBlue);
    color: var(--whisperGray);
    border: 1px solid var(--shaleBlue);
}

.active>.page-number.page-link{
    background-color: var(--shaleBlue02);
    border: solid 1px var(--shaleBlue02);
    color: var(--castIron);
}

@media only screen and (min-width: 768px) {
    .wrapper table{
        margin-bottom: 32px;
    }
}

@media only screen and (min-width: 1000px) {
    .wrapper.jobs .main{
        display: block;
    }
    .wrapper table{
        margin-bottom: 48px;
    }
}

/* create a job page */

.wrapper.jobs label {
    font-size: 24px;
    font-weight: 600;
}

.wrapper.jobs .preview p{
    font-style: italic;
}

.wrapper.jobs .main .create-job-wrapper>div{
    margin-bottom: 24px;
}

.wrapper.jobs ul label {
    margin-left: -14px;
}

.wrapper li:last-of-type{
    padding-bottom: 0;
}

.wrapper .create-job-wrapper .col, .wrapper.jobs .form-check{
    margin-bottom: 24px;
}

.wrapper.jobs .closing-item{
    margin-bottom: 48px;
}

.wrapper.jobs .sub-title{
    margin-bottom: 24px;
}

.wrapper.jobs .form-check-label.label-title {
    font-size: 16px;
    margin-bottom: 8px;
}

.wrapper.jobs .label-body {
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
    margin-top: -4px;
}

.wrapper.jobs .create-job-wrapper .label-body {
    margin-bottom: 24px;
    margin-top: 0;
}

.wrapper.jobs .form-check-label.label-body span {
    font-style: italic;
}

.wrapper .form-check-input:checked {
    background-color: var(--shaleBlue);
    border-color: var(--shaleBlue);
}

.create-job-wrapper .preview li button {
    margin-left: 24px;
    padding: 0 10px 3px 10px;
    color: var(--shaleBlue06);
    font-weight: 600;
    border: 2px solid var(--shaleBlue02);
}

.create-job-wrapper .preview li button:hover, .wrapper.job-details .table button:hover {
    background-color: var(--shaleBlue06);
    color: var(--whisperGray);
    border: 2px solid var(--shaleBlue06);
}

.wrapper .create-job-wrapper h4{
    margin: 0 0 24px 0;
}
.wrapper .create-job-wrapper ul, .wrapper .create-job-wrapper p{
    margin: 0 0 24px 0;
    padding-bottom: 0;
}

.wrapper .special-info{
    font-style: italic;
    font-weight: 600;
}
.wrapper .second-part .special-info{
    margin-bottom: 8px;
}

@media only screen and (min-width: 1200px) {
    .wrapper.jobs label {font-size: 32px;}
    .wrapper.jobs .form-check-label.label-title{
        font-size: 24px;
    }
    .wrapper.jobs .form-check-label.label-body {
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .wrapper.jobs .main .create-job-wrapper>div, .wrapper .create-job-wrapper .col{
        margin-bottom: 32px;
    }
    .wrapper .create-job-wrapper ul, .wrapper .create-job-wrapper p{
        margin: 0 0 32px 0;
    }
}

@media only screen and (min-width: 1000px) {
    .wrapper .create-job-wrapper ul, .wrapper .create-job-wrapper p{
        margin: 0 0 48px 0;
    }
}

@media only screen and (min-width: 1200px) {
    .wrapper.jobs .form-check-label.label-body {
        margin-top: -12px;
    }
    .wrapper.jobs .form-check-label.label-title {
        margin-top: -6px;
        margin-bottom: 12px;
    }
}

/* Job Detail page */

.wrapper.job-details .small-title{
    padding: 0;
    font-size: 32px;
    margin-bottom: 48px;
}

.wrapper.job-details ul, .wrapper.job-details li{
    padding: 0;
}

.wrapper.job-details #colored_tab {
    background-color: transparent;
    color: var(--castIron);
}

.wrapper.job-details .nav-link:hover {
    background-color: var(--shaleBlue03);
    color: var(--castIron);
    text-decoration: none;
}

.wrapper.job-details .nav-link:hover svg path{
    color: var(--castIron);
}

.wrapper.job-details .nav-link svg path{
    color: var(--shaleBlue06);
}

.wrapper.job-details .nav-link.active svg path{
    color: var(--castIron);
}

.wrapper.job-details .mobile-tabs .nav-link{
    padding-left: 32px;
    padding-right: 32px;
}

.wrapper.job-details h1{
    margin-bottom:8px;
}

.wrapper.job-details ul{
    margin-bottom: 24px;
}

.wrapper.job-details h3{
    margin-bottom: 24px;
}

.wrapper.job-details .main p:last-of-type {
    padding: 0 0 24px 0;
}

.wrapper.job-details #chat-container{
    margin: 0;
}

.wrapper.job-details .mb-3 {
    display:grid;
    grid-template-columns: 1fr 48px;
    column-gap: 24px;
}

.wrapper.job-details .mb-3 label{
    grid-column: 1/3;
}

.wrapper.job-details .flexChildText, .wrapper.job-details .flexChildButton {
    float: none;
    width: 100%;
}
.wrapper.job-details .flexChildText textarea{
    border-radius: 8px;
    height: 100px;
}

.wrapper.job-details .bs-chat-area {
    padding: 8px !important;
}

.flexChildButton .bi.bi-send{
    color: var(--whisperGray);
}

.flexChildButton .btn.btn-primary:hover .bi.bi-send{
    color: var(--castIron);
}

.wrapper.job-details #chat-container{
    height: auto;
    max-height: 400px;
    overflow-y: scroll;
    margin-bottom: 24px;
}

.wrapper.job-details #round{
    all: unset;
}

.wrapper.job-details .card-body{
    border: 1px solid var(--shaleBlue02);
    background-color: var(--shaleBlue03);
    padding: 8px 16px;
    border-radius: 16px;
    margin-bottom: 24px;
    width: 90%;
    
}

.wrapper.job-details .card-body p, .wrapper.job-details .card-body b{
    font-size: 16px !important;
}

.wrapper.job-details .user-chat.mb-3.text-bg-primary, .wrapper.job-details .admin-chat.mb-3.text-bg-primary{
    display:block;
    background-color: transparent !important;
    border: none !important;
}

/* for some reason the admin-chat had to be named user-chat and vice versa.... i dont know why but that is the only way it works */

.wrapper.job-details .user-chat .card-body{
    background-color: var(--shaleBlue05);
    color: var(--whisperGray);
}

.wrapper.job-details .user-chat .card-body p, .wrapper.job-details .user-chat .card-body b{
    color: var(--whisperGray);
}

.wrapper.job-details .admin-chat .card-body{
    margin-left: 10%;
}

.wrapper.job-details .card:last-of-type .card-body{
    margin-bottom: 0;
}

.wrapper.job-details .card-body .card-title{
    margin: 0;
    padding-bottom: 8px;
}

.wrapper.job-details .card-body p:last-of-type{
    padding-bottom: 0;
}

.wrapper.job-details .table>thead {
    vertical-align: bottom;
    border-bottom: 1px solid var(--bs-table-border-color);
}

.wrapper.job-details .table button {
    margin-left: 24px;
    padding:0 10px 3px 10px;
    color: var(--shaleBlue06);
    font-weight: 600;
    border: 2px solid var(--shaleBlue02);
}

.wrapper.job-details .btn.btn-primary, .wrapper.job-details .btn.btn-smaller{
    background-color: var(--shaleBlue);
    color: var(--whisperGray);
    box-shadow: 1px 1px 4px var(--graphite);
    /* margin: 8px; */
    border: none;
    font-weight: 400;
    align-self: center;
}

.collab-table{
    display: flex;
    align-items: center;
    column-gap: 5%;
}

.collab-table .btn.btn-primary.btn-smaller, .collab-table span, .collab-table .btn.btn-outline-secondary.btn-remove{
    margin-left: 0;
}

/* .collab-table .btn.btn-primary.btn-smaller{
    margin-top: 2px;
} */

.collab-table .btn.btn-primary.btn-smaller:hover{
    border: none;
    background-color: var(--shaleBlue02);
    color: var(--castIron);
}

.wrapper.job-details .input-group.mb-3 {
    display: grid;
    grid-template-columns: 1fr 100px;
    column-gap: 0;
}

.wrapper.job-details .input-group.mb-3 div {
    border-radius: 8px 0 0 8px;
}

.wrapper.job-details .input-group.mb-3 button {
    border-radius: 0 8px 8px 0;
    border: 1px solid var(--shaleBlue);
    background-color: var(--shaleBlue);
    color: var(--whisperGray);
}

.wrapper.job-details .input-group.mb-3 button:hover {
    border: 1px solid var(--shaleBlue02);
    background-color: var(--shaleBlue02);
    color: var(--castIron);
}

.desktop-tabs{
    display: none;
}

.tab-content-titles{
    display: grid;
    grid-template-columns: 1fr minmax(120px, auto);
    align-items: start;
}

.tab-content-titles span{
    margin-top: 12px;
}

.wrapper.job-details .TabContent ul{
    margin-left: 16px;
}

@media only screen and (min-width: 576px) {
    .desktop-tabs{
        display: block;
    }
    .mobile-tabs{
        display: none;
    }
}


@media only screen and (min-width: 1000px) {
    .wrapper.job-details .main{
        /* max-width: 970px; */
        display: grid;
        grid-template-columns: 3fr 2fr;
        align-items: start;
        justify-items: start;
        column-gap: 32px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .wrapper.job-details h1, .wrapper.job-details h2{
        grid-column: 1/3;
    }

    .wrapper.job-details .main div h2{
        padding-top: 0;
    }

    .wrapper.job-details .main>div{
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) {
    .wrapper.job-details .main{
        column-gap: 56px;
    }
}

.spinner {
    font-size: 24px;
    animation: spin infinite 1.25s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Capabilities */
.badge {
    margin-left: 5px;
}

.flex {
    display: grid;
    grid-template-columns: auto 300px;
    align-items: center;
}